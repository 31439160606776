<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-btn v-if="isLoggedIn" @click="logout" text>
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-alert border="right" colored-border type="error" elevation="2">
          {{ message }}
        </v-alert>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
  },
  props: {
    message: {
      type: String,
      default: "An error occurred.",
    },
  },
  methods: {
    ...mapActions({
      setLogout: "user/logout",
    }),
    logout() {
      this.setLogout();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.centered-content {
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
